/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import _ from 'lodash';

import sparkDirective from "../common/directives/spark-directive";
import resources from '../../components/api/resources/resources';
import * as moment from "moment/moment";

const MODULE_NAME = 'line-item-diag-controller';

angular.module(MODULE_NAME, [uiBootstrap, translate, resources, sparkDirective])

  .controller('LineItemDiagnosticsController', function ($scope, $interval, $interpolate, model, LocalUserPermissions, Bids, statsResource, DiagnosticRequestExecution) {
    const ctrl = this,
      intervalRepeat = 40;
    let request,
      intervalPromise,
      intervalCounter,
      intervalTime = 1250,
      initData = function() {
        ctrl.results = {messages: []};
        intervalCounter = 0;
      },
      checkComplete = function() {
        DiagnosticRequestExecution.get({id: request.id}).$promise.then(function(results) {
          results.results.forEach(function(result) {
            result.message.apiTranslation = $interpolate(result.message.text)(result.message.parameters);
          });
          intervalCounter++;
          if (results.isCompleted()) {
            ctrl.results = results;
            $interval.cancel(intervalPromise);
            ctrl.launchDisabled = false;
          } else if (!results.inProgress() || intervalCounter >= intervalRepeat) {
            ctrl.results = results;
            ctrl.results.failed = true;
            $interval.cancel(intervalPromise);
            ctrl.launchDisabled = false;
          } else {
            ctrl.results = results;
          }
        });
      };

    ctrl.launchDisabled = false;

    ctrl.showBidUpdates = model.bidSpecification.bidStrategy === 'DYNAMIC';
    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
    ctrl.model = model;
    ctrl.infoReset = {};

    ctrl.getFloorPrices = function() {
      ctrl.infoReset.info();
    };

    ctrl.hasBidResults = false;
    ctrl.getBidUpdates = function() {
      ctrl.bidProcessing = true;
      // We will limit ourselves to only the last day of data
      const statsStartDate = moment.utc().subtract(1, 'd');
      // We will display these ordered by the site spend; highest spend first
      statsResource.get({
        lineItemId: model.id,
        startDate: statsStartDate,
        groupBy: 'SITE'
      }, function (response) {
        ctrl.sites = _.sortBy(response.chunks, ['totalCost.amount']).reverse().map(function(chunk) {
          let site = chunk.site;
          site.cost = chunk.totalCost;
          return site;
        });
        Bids.bidUpdates(model.id).then(function (data) {
          ctrl.siteBids = [];
          let bidsBySite = {};
          data.forEach(function (siteUpdates) {
            siteUpdates.updates = siteUpdates.updates.filter(bid => moment(bid.time) > statsStartDate);
            if (siteUpdates.site && siteUpdates.site.id) {
              bidsBySite[siteUpdates.site.id] = siteUpdates;
            }
          });
          ctrl.sites.forEach(function (site) {
            let siteBids = bidsBySite[site.id];
            if (siteBids) {
              siteBids.cost = site.cost;
              ctrl.siteBids.push(siteBids);
            }
          });
          ctrl.hasBidResults = true;
          ctrl.bidProcessing = false;
        });
      });
    };

    initData();
    ctrl.launchRequest = function() {
      initData();
      ctrl.launchDisabled = true;
      DiagnosticRequestExecution.start({
        testType: 'LINE_ITEM',
        lineItem: model.id
      }).then(function(result) {
        request = result;
        intervalPromise = $interval(checkComplete, intervalTime, intervalRepeat);
        intervalTime = 5000;
      });
    };

    $scope.$on('$destroy', function() {
      $interval.cancel(intervalPromise);
    });
  });

export default MODULE_NAME;